.container-fluid {
  padding: 50px 0;
}

footer {
  background-color: #1baf3b;
  height: 60vh;
  color: #fff;
  padding: 50px 0;
}
@media (max-width: 768px) {
  footer {
    height: 100vh;
    padding: 20px 0;
    margin-top: -20em;
  }

}

footer h3 {
  font-size: 2rem;
  font-weight: 700;
}

footer p {
  font-size: 1.5rem;
  font-weight: 200;
  margin-top: 20px;
  margin-bottom: 10px;
}

footer .connect-text h4 {
  font-weight: 500;
  margin-top: 50px;
}

.social-icons a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
}

footer .footer-icons {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 5px;
}

footer .footer-icons li {
  font-size: 30px;
}

footer .footer-icons li:first-child {
  margin-right: 20px;
}

footer .footer-icons li:nth-child(2) {
  margin-right: 20px;
}

footer .footer-icons li:nth-child(3) {
  margin-right: 20px;
}

footer .footer-links {
  list-style: none;
  margin-top: 50px;
  padding: 0;
}

footer .footer-links li {
  font-size: 1.5rem;
  font-weight: 200;
}

footer .footer-links li a {
  color: #fff;
  text-decoration: none;
}


@media (max-width: 768px) {
  .footer .col-lg-4,
  .footer .col-lg-2,
  .footer .col-lg-6 {
    margin-bottom: 30px;
  }
}