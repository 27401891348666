.App-header {
    background-image: url("/src/assets/img/bg.png");
    background-color: #00000080;
    background-repeat:no-repeat; 
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;

}
@media (max-width: 768px) {
    .App {
        background-image: none;
    }

    .App-header {
        background-color: #061309F0;
    }
}
